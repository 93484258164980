<template>
  <div>
    <modal-delete-order
      :order="selectedOrder"
      :stage="modalStage"
      :modalMessage="modalMessage"
      @confirmDeleteOrder="confirmDeleteOrder()"
      :modalTitle="deleteModalTitle"
      :next="nextModal"
      @next="handleNextModal"
    />
    <modal-filter
      :applyFilter="handleFilter"
      :resetFilters="resetFilters"
      :filterDateField="filterDateField"
    >
      <template #dateFilter>
        <datepicker-filter
          :end_date="end_date"
          :filterDateField="filterDateField"
          :start_date="start_date"
          :dateHandler="dateHandler"
          @handleFilterDateField="handleFilterDateField"
          :showLabel="true"
          :isModal="true"
        />
      </template>
    </modal-filter>
    <modal-process-order
      :order="selectedOrder"
      :stage="modalStage"
      :modalMessage="modalMessage"
      :modalTitle="proccessModalTitle"
      :next="nextModal"
      @next="handleNextModal"
      @confirmProcessOrder="confirmProcessOrder()"
    />
    <modal-assignments 
      :order="selectedOrder"
    />
    <modal-validate-order
      :order="selectedOrder"
      :stage="modalStage"
      :modalMessage="modalMessage"
      :modalTitle="validationModalTitle"
      :next="nextModal"
      @next="handleNextModal"
      @confirmValidateOrder="confirmValidateOrder()"
    />
    <modal-update-grade-order
      :order="localOrder"
      :stage="modalStage"
      :modalMessage="modalMessage"
      :modalTitle="updateOrderTitle"
      :next="nextModal"
      @next="handleNextModal"
      @confirmUpdateOrder="confirmUpdateOrder()"
    />
    <modal-update-order
      :order="localOrder"
      :stage="modalStage"
      :modalMessage="modalMessage"
      :next="nextModal"
      @next="handleNextModal"
      @confirmUpdateOrder="confirmUpdateOrder()"
    />
    <modal-notes
      :order="selectedOrder"
      :stage="modalStage"
    />
    <modal-messages
      :order="selectedOrder"
      :stage="modalStage"
    />
    <modal-banner-enter
      :order="selectedOrder"
      :stage="modalStage"
      :modalMessage="modalMessage"
      :next="nextModal"
      @next="handleNextModal"
      @confirmBannerEnter="confirmBannerEnter()"
      :modalTitle="enterIntoBannerModalTitle"
    />
    <modal-banner-grade
      :order="selectedOrder"
      :stage="modalStage"
      :modalMessage="modalMessage"
      @confirmBannerGrade="confirmBannerGrade()"
      :next="nextModal"
      @next="handleNextModal"
      :modalTitle="gradesIntoBannerModalTitle"
    />
    <modal-refund
      :order="selectedOrder"
      :stage="modalStage"
      :modalMessage="modalMessage"
      @confirmRefund="confirmRefund()"
      :next="nextModal"
      @next="handleNextModal"
    />
    <modal-send-resubmit
      :order="selectedOrder"
      :stage="modalStage"
      :modalMessage="modalMessage"
      @confirmSendResubmit="confirmSendResubmit()"
      :next="nextModal"
      @next="handleNextModal"
      :modalTitle="sendResubmitModalTitle"
    />
    <Header ref="Header" parent="dashboard" />
    <div class="dashboard">
      <div class="main">
        <div class="nav-section">
          <b-button
            v-if="this.$route.query.objectId"
            variant="secondary"
            class="btn ml-2"
            @click="reloadDashboard"
            >Back to Dashboard</b-button
          >
        </div>
        <div class="nav-section" v-if="!this.$route.query.objectId">
          <div class="nav-left">
            <div class="labelContainer">
              <span class="title">Get Started</span>
            </div>
            <div class="selectContainer" style="font-size: 12px">
              <b-form-select v-model="selectedView" @change="changeView()">
                <b-form-select-option
                  v-for="(view, v) in getViews()"
                  :key="v"
                  :value="view.name"
                >
                  {{ view.name }}
                  <span
                    class="counter"
                    v-if="
                      $store.state.viewsCount &&
                      $store.state.viewsCount[view.name] !== undefined
                    "
                  >
                    ({{ $store.state.viewsCount[view.name] }})</span
                  >
                </b-form-select-option>
              </b-form-select>
              <!-- <v-select :clearable="true" @input="changeView" style="width: 100%" :value="selectedView" label="name" :reduce="view => view.name"  :options="views"></v-select> -->
            </div>
          </div>
          <div class="nav-right" v-if="$store.state.partners.length >= 1">
            <div class="labelContainer">
              <span class="title">Partner</span>
            </div>
            <div class="selectContainer" style="width: 500px; max-width: 100%;">
              <v-select
                v-model="$store.state.user.partnerId"
                style="width: 100%; min-width: 320px; background: #fff"
                label="partner_name"
                :reduce="(partner) => partner.partner_id"
                :clearable="false"
                :options="partners"
              ></v-select>
            </div>
          </div>
        </div>
        <b-collapse id="collapse-filters" class="mt-2">
          <div class="view-main">
            <div class="view-body">
              <p 
                class="text-left py-2 m-0"
                v-if="$store.state.user.can_use_date_filters.length > 1"
              >
                Set a Date Filter
              </p>
              <div
                class="main-action-wrapper"
                v-if="$store.state.user.can_use_date_filters.length > 1"
              >
                <div class="dateFilter">
                  <datepicker-filter
                    :end_date="end_date"
                    :filterDateField="filterDateField"
                    :start_date="start_date"
                    :dateHandler="dateHandler"
                    :applyFilter="handleFilter"
                    :resetFilters="resetFilters"
                    @handleFilterDateField="handleFilterDateField"
                  />
                </div>
                <b-button
                  v-if="
                    checkIfAllowed(['admin', 'uop_admin']) ||
                    checkIfPartnerMatch(['CT', 'MT', 'C4T', 'TE'])
                  "
                  variant="success"
                  class="btn ml-2"
                  :disabled="!(start_date && end_date && filterDateField)"
                  @click="handleDownloadExport"
                  >Download Export</b-button
                >
              </div>
              <p class="text-left py-2 m-0">Set a Field Filter</p>
              <div class="main-action-wrapper" style="max-width: 30rem;">
                  <b-form-select
                    v-model="filterFieldKey"
                    class="mr-2"
                  >
                    <b-form-select-option disabled value="">Select a Field</b-form-select-option>
                    <b-form-select-option value="order">UC Order ID</b-form-select-option>
                    <b-form-select-option value="cashnet_transaction_id">Cashnet TX ID</b-form-select-option>
                    <b-form-select-option value="uop_student_id">UOP Student ID</b-form-select-option>
                    <b-form-select-option value="course_number">Course Number</b-form-select-option>
                    <b-form-select-option value="course_title">Course Title</b-form-select-option>
                    <b-form-select-option
                     v-if="checkIfAllowed(['admin'])"
                      value="wc_order_id"
                    >
                      TF Order ID
                    </b-form-select-option>
                    <b-form-select-option
                     v-if="checkIfAllowed(['admin'])"
                      value="wc_item_id"
                    >
                      TF Order Item ID
                    </b-form-select-option>
                  </b-form-select>
                  <b-form-input
                    v-model="filterFieldValue"
                    placeholder="Enter a Value"
                    type="text"
                  />
                <b-button
                  variant="primary"
                  class="btn ml-2"
                  :disabled="this.filterFieldKey == '' || this.filterFieldValue == ''"
                  @click="handleFilter"
                  >Apply</b-button
                >
                <b-button
                  variant="warning"
                  class="btn ml-2"
                  style="color:white;"
                  v-if="this.filterFieldKey !== '' && this.filterFieldValue !== ''"
                  @click="resetFilters({type: 'field'})"
                  >Clear</b-button
                >
              </div>
            </div>
          </div>
        </b-collapse>
        <!-- check if set date filter true then apply this class -->
        <div class='main-action-wrapper' v-if="!this.$route.query.objectId">
          <div class="search-div">
            <v-select
              v-model="selectedCustomerId"
              :label="selectedCustomerLabel"
              :get-option-label="(customer) => customer.option_label"
              :reduce="(customer) => customer._id"
              :options="lastFilteredCustomers"
              placeholder="Search Students by Name, Email, or DOB"
              @search="handleCustomersSearch"
            >
              <!-- Custom "No Customers" message -->
              <template #no-options>
                <div class="vs__dropdown-option">{{noCustomerResultsMessage}}</div>
              </template>
            </v-select>
          </div>
          <div class="view-buttons">
            <b-button class="mr-2" v-b-toggle.collapse-filters variant="primary">Filter</b-button>
            <b-tooltip 
              v-if="disableActionDropdown()"
              target="bulkActionDropdown" 
              triggers="hover"
            >
              No Bulk Actions are available on this view
            </b-tooltip>
            <b-dropdown 
              id="bulkActionDropdown" 
              right 
              variant="primary" 
              text="Selected Bulk Actions"
              :disabled="disableActionDropdown()"
            >
              <b-dropdown-item
                v-if="
                  this.$store.state.user.user_role !== 'uop_admin' &&
                  showSelectedActions
                "
                variant="danger"
                class="btn btn--actions"
                @click="deleteSelectedOrdersModal()"
                :disabled="!selectedRows.length"
                >Delete Orders</b-dropdown-item
              >
              <b-dropdown-item
                v-if="
                  (this.$store.state.user && 
                  this.$store.state.user.can_process &&
                  this.$store.state.user.user_role !== 'uop_admin') &&
                  showSelectedActions
                "
                variant="success"
                class="btn btn--actions"
                @click="processSelectedOrdersModal()"
                :disabled="!selectedRows.length"
                >Process Orders</b-dropdown-item
              >
              <b-dropdown-item
                v-if="
                  this.$store.state.user.user_role !== 'uop_admin' &&
                  showValidateAction
                "
                variant="success"
                class="btn btn--actions"
                @click="validateSelectedOrdersModal()"
                :disabled="!selectedRows.length"
                >Validate Orders</b-dropdown-item
              >
              <b-dropdown-item
                v-if="
                  checkIfAllowed(['admin', 'uop_admin']) &&
                  checkIfCurrentViewMatch([
                    VIEWS.REGISTRATIONS_NEEDED_TO_BE_ENTERED_IN_BANNER,
                    VIEWS.PROCESSED_REGISTRATIONS,
                  ])
                "
                variant="success"
                class="btn btn--actions"
                @click="registerIntoBannerSelectedOrdersModal()"
                :disabled="!selectedRows.length"
                >Enter Registrations into Banner</b-dropdown-item
              >
              <b-dropdown-item
                v-if="
                  checkIfAllowed(['admin', 'uop_admin']) &&
                  checkIfCurrentViewMatch([
                    VIEWS.GRADES_NEEDED_TO_BE_ENTERED_IN_BANNER,
                    VIEWS.PROCESSED_REGISTRATIONS,
                  ])
                "
                variant="success"
                class="btn btn--actions"
                @click="gradesIntoBannerSelectedOrdersModal()"
                :disabled="!selectedRows.length"
                >Enter Course Grades into Banner</b-dropdown-item
              >
            </b-dropdown>
          </div>
        </div>
        <div class="table-controls" v-if="!this.$route.query.objectId">
          <div class="controls-left">
            Showing {{ orderIndexStart }}-{{ orderIndexEnd }} of
            {{ totalOrders }}
            <span
              v-if="selectedRows.length > 0"
              variant="primary" 
              class="text-primary font-weight-bold clear-selection ml-2" 
              @click="clearSelection()"
            >
              Clear selection
            </span>
          </div>
          <div class="controls-center">
            <span class="btn-page" :disabled="true" @click="prevPage()"
              >&#60;</span
            >
            <b-form-input
              class="filterInput"
              type="number"
              v-model.number="currentPageInput"
              :value="currentPageInput"
              :max="totalPages"
            />
            of &nbsp; &nbsp; {{ totalPages }}
            <span class="btn-page" @click="nextPage()">&#62;</span>
          </div>
          <div class="controls-right">
            <v-icon
              style="width: 30px"
              name="sync"
              spin
              @click="changeView()"
            ></v-icon>
            <b-form-select
              size="sm"
              v-model="pageSize"
              @change="handleCountSelectChange"
              :value="pageSize"
            >
              <b-form-select-option value="5">5</b-form-select-option>
              <b-form-select-option value="10">10</b-form-select-option>
              <b-form-select-option value="25">25</b-form-select-option>
              <b-form-select-option value="50">50</b-form-select-option>
              <b-form-select-option value="75">75</b-form-select-option>
              <b-form-select-option value="100">100</b-form-select-option>
              <b-form-select-option value="125">125</b-form-select-option>
              <b-form-select-option value="150">150</b-form-select-option>
            </b-form-select>
          </div>
        </div>
        <div class="table-holder" v-if="!this.$route.query.objectId">
          <order-table
            ref="orderTable"
            :orders="orders"
            @selectRow="selectRow"
            :selectedRows="selectedRows"
            rowSelect
            :checkIfCurrentViewMatch="checkIfCurrentViewMatch"
            :VIEWS="VIEWS"
          />
        </div>
        <div class="view-main">
          <div class="view-body">
            <template v-if="selectedRows.length > 0">
              <inspect-selected-course
                :order="selectedOrder"
                @updateGradeOrder="handleGradeOrderUpdate"
                @updateOrder="handleOrderUpdate"
                @validateSelectedOrder="confimValidateOrderModal"
                @deleteSelectedOrder="deleteSelectedSingleOrderModal"
                @processSelectedOrder="processSelectedSingleOrderModal"
                :selectedView="selectedView"
                @enterBanner="registerIntoBannerSelectedSingleModal"
                @refundOrder="refundSelectedSingleOrderModal"
                @sendResubmitOrder="sendResubmitSelectedSingleOrderModal"
                @enterGradeToBanner="gradesIntoBannerSelectedSingleModal"
                @viewCourseAssignments="viewCourseAssignments"
              />
              <div class="title">
                <h4 class="text-left">Registrations Related to Student</h4>
              </div>
            </template>
            <tabs class="mt-2 mb-4" :tabs="tabs" v-if="selectedRows.length > 0">
              <template #courseHistory>
                <p class="tableHeading">All Registrations from Student</p>
                <div class="table-controls">
                  <div class="controls-left">
                    Showing {{ relatedNotDeletedRecords.length }}
                  </div>
                  <div class="controls-right">
                    <v-icon name="sync" spin @click="changeView()"></v-icon>
                  </div>
                </div>
                <div class="table-holder">
                  <order-table :orders="relatedNotDeletedRecords" />
                </div>
              </template>
              <template v-if="selectedRows.length > 0" #inProgress>
                <p class="tableHeading">
                In-Progress (Courses that have not been graded by Partner)
                </p>
                <div class="table-controls">
                  <div class="controls-left">
                    Showing {{ relatedInProgress.length }} results
                  </div>
                  <div class="controls-right">
                    <v-icon name="sync" spin @click="changeView()"></v-icon>
                  </div>
                </div>
                <div class="table-holder">
                  <order-table :orders="relatedInProgress" />
                </div>
              </template>
              <template v-if="selectedRows.length > 0" #completed>
                <p class="tableHeading">
                  Completed (Courses have been graded by Partner)
                </p>
                <div class="table-controls">
                  <div class="controls-left">
                    Showing {{ relatedCompletedRecords.length }} results
                  </div>
                  <div class="controls-right">
                    <v-icon name="sync" spin @click="changeView()"></v-icon>
                  </div>
                </div>
                <div class="table-holder">
                  <order-table :orders="relatedCompletedRecords" />
                </div>
              </template>
              <template v-if="selectedRows.length > 0" #duplicate>
                <p class="tableHeading">Duplicate Registrations</p>
                <div class="table-controls">
                  <div class="controls-left">
                    Showing {{ relatedDuplicateOrders.length }} results
                  </div>
                  <div class="controls-right">
                    <v-icon name="sync" spin @click="changeView()"></v-icon>
                  </div>
                </div>
                <div class="table-holder">
                  <order-table :orders="relatedDuplicateOrders" />
                </div>
               </template>
              <template v-if="selectedRows.length > 0" #deleted>
                <p class="tableHeading">Deleted Registrations</p>
                <div class="table-controls">
                  <div class="controls-left">
                    Showing {{ relatedDeletedRecords.length }} results
                  </div>
                  <div class="controls-right">
                    <v-icon name="sync" spin @click="changeView()"></v-icon>
                  </div>
                </div>
                <div class="table-holder">
                  <order-table :orders="relatedDeletedRecords" :VIEWS="VIEWS" :checkIfCurrentViewMatch="checkIfCurrentViewMatch" />
                </div>
              </template>
            </tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatepickerFilter from "../components/Molecules/DatepickerFilter.vue";
import OrderTable from "../components/Molecules/OrderTable.vue";
import Header from "../components/Molecules/Header.vue";
import ENUMS from "@/helpers/constants";
import ModalFilter from "../components/Molecules/ModalFilter.vue";
import download from "downloadjs";
import ModalAssignments from "../components/Molecules/ModalAssignments.vue";

const InspectSelectedCourse = () =>
  import(
    /*webpackChunkName inspectSelectedCourse */ "@/components/Molecules/InspectSelectedCourse.vue"
  );
const ModalDeleteOrder = () =>
  import(
    /* webpackChunkName: "ModalDeleteOrder" */ "@/components/Molecules/ModalDeleteOrder.vue"
  );
const ModalProcessOrder = () =>
  import(
    /* webpackChunkName: "ModalProcessOrder" */ "@/components/Molecules/ModalProcessOrder.vue"
  );
const ModalValidateOrder = () =>
  import(
    /* webpackChunkName: "ModalValidateOrder" */ "@/components/Molecules/ModalValidateOrder.vue"
  );
const ModalUpdateGradeOrder = () =>
  import(
    /* webpackChunkName: "ModalUpdateGradeOrder" */ "@/components/Molecules/ModalUpdateGradeOrder.vue"
  );
const ModalUpdateOrder = () =>
  import(
    /* webpackChunkName: "ModalUpdateOrder" */ "@/components/Molecules/ModalUpdateOrder.vue"
  );
const ModalNotes = () =>
  import(
    /* webpackChunkName: "ModalModalNotes" */ "@/components/Molecules/ModalNotes.vue"
  );
const ModalMessages = () =>
  import(
    /* webpackChunkName: "ModalModalMessages" */ "@/components/Molecules/ModalMessages.vue"
  );
const ModalBannerEnter = () =>
  import(
    /* webpackChunkName: "ModalBannerEnter" */ "@/components/Molecules/ModalBannerEnter.vue"
  );
const ModalBannerGrade = () =>
  import(
    /* webpackChunkName: "ModalBannerGrade" */ "@/components/Molecules/ModalBannerGrade.vue"
  );
const ModalRefund = () =>
  import(
    /* webpackChunkName: "ModalRefund" */ "@/components/Molecules/ModalRefund.vue"
  );
const ModalSendResubmit = () =>
  import(
    /* webpackChunkName: "ModalSendResubmit" */ "@/components/Molecules/ModalSendResubmit.vue"
  );
const Tabs = () =>
  import(/* webpackChunkName: "Tabs" */ "@/components/Molecules/Tabs.vue"
  );

export default {
  name: "Dashboard",
  data() {
    return {
      lastFilteredCustomers: [], // Stores the last non-null result of filtered customers
      customersResultLimit: 1500,
      searchTextInCustomerFilter: '',
      firstLoad: true,
      selectedView: "",
      // TODO The full object is needed for conditional table columns, selectedView could be replaced eventually - John
      selectedViewObject: {},
      selectedCustomerId: null,
      modalStage: "",
      modalMessage: "",
      orderIndexStart: 1,
      orderIndexEnd: 5,
      currentPage: 1,
      selectedRows: [],
      deleteModalTitle: "Delete Order Confirmation",
      proccessModalTitle: "Process Payment Confirmation",
      validationModalTitle: "Click CONFIRM to Validate Course",
      enterIntoBannerModalTitle:
        "Are you sure you want to mark this course Entered into Banner?",
      gradesIntoBannerModalTitle:
        "Are you sure you want to mark this course as Graded via Banner at UOP?",
      sendResubmitModalTitle:
        "Are you sure you want to send a resubmit email for this order?",
      updateOrderTitle: "",
      nextModal: null,
      currentModalCount: 0,
      pageSize: 5,
      start_date: "",
      end_date: "",
      filterDateField: "",
      filterFieldKey: "",
      filterFieldValue: "",
      modalMode: '',
      studentIdChanged:null,
      VIEWS: [],
      views: [
        //START PARTNER VIEWS
        {
          name: "View Unvalidated Registrations",
          user_roles: ["partner"],
          view_count: true,
          params: {
            order_status: false,
            course_status: false,
            validation_status: false,
            deleted_status: false,
          },
        },
        {
          name: "View New Registrations",
          user_roles: ["partner"],
          view_count: true,
          params: {
            order_status: false,
            course_status: false,
            validation_status: true,
            uop_process_failed: false,
            deleted_status: false,
          },
        },
        {
          name: "View In-Progress Courses",
          user_roles: ["partner"],
          params: {
            order_status: true,
            course_status: false,
            deleted_status: false,
            refund_status: false,
          },
        },
        {
          name: "View In-Progress TF Assignments",
          user_roles: ["partner"],
          params: {
            order_status: true,
            course_status: false,
            ld_assignments: true,
            deleted_status: false,
            refund_status: false,
          },
        },
        {
          name: "View Completed Courses",
          user_roles: ["partner"],
          params: {
            order_status: true,
            course_status: true,
            complete_course_grade: true,
            deleted_status: false,
            refund_status: false,
          },
        },
        {
          name: "View Incomplete Courses",
          user_roles: ["partner"],
          params: {
            order_status: true,
            course_status: true,
            complete_course_grade: false,
            deleted_status: false,
            refund_status: false,
          },
        },
        {
          name: "View All Registrations",
          user_roles: ["partner"],
          params: {
            uop_process_failed: false,
            deleted_status: false,
            /*refund_status: false,*/
          },
        },
        {
          name: "View Failed Payment Registrations",
          user_roles: ["partner"],
          params: {
            uop_process_failed: true,
            deleted_status: false,
            refund_status: false,
          },
        },
        {
          name: "View Deleted Registrations",
          user_roles: ["partner"],
          params: {
            deleted_status: true,
          },
        },
        {
          name: "View Graded Courses Via Banner",
          user_roles: ["partner"],
          params: {
            grade_status: true,
            grade_type: true,
            deleted_status: false,
            refund_status: false,
          },
        },
        {
          name: "View Registrations Entered Via Banner",
          user_roles: ["partner"],
          params: {
            order_status: true,
            banner_status: true,
            deleted_status: false,
            refund_status: false,
          },
        },
        //START UOP ADMIN VIEWS
        {
          name: "View Registrations Needed To Be Entered in Banner",
          user_roles: ["uop_admin"],
          view_count: true,
          params: {
            order_status: true,
            banner_status: false,
            deleted_status: false,
            refund_status: false,
          },
        },
        {
          name: "View Registrations Entered Into Banner",
          user_roles: ["uop_admin"],
          params: {
            banner_status: true,
            deleted_status: false,
            refund_status: false,
          },
        },
        {
          name: "View Graded Into InsidePacific",
          user_roles: ["uop_admin"],
          params: {
            banner_status: true,
            grade_type: false,
            deleted_status: false,
            refund_status: false,
          },
        },
        {
          name: "View Grades Needed To be Entered in Banner",
          user_roles: ["uop_admin"],
          view_count: true,
          params: {
            grade_status: false,
            grade_type: true,
            course_status: true,
            deleted_status: false,
            refund_status: false,
          },
        },
        {
          name: "View All Graded Courses",
          user_roles: ["uop_admin"],
          params: {
            grade_status: true,
            deleted_status: false,
            refund_status: false,
          },
        },
        {
          name: "View Processed Registrations",
          user_roles: ["uop_admin"],
          params: {
            order_status: true,
            deleted_status: false,
            refund_status: false,
          },
        },
        {
          name: "View Refunded Registrations",
          user_roles: ["partner", "uop_admin"],
          params: {
            refund_status: true,
          },
        },
      ],
      tabs: [
        //{ title: "Selected Course", slot: "selectedcourse" },
        { title: "Course History", slot: "courseHistory" },
        { title: "In-Progress", slot: "inProgress" },
        { title: "Completed", slot: "completed" },
        { title: "Duplicate", slot: "duplicate" },
        { title: "Deleted", slot: "deleted" },
      ],
      localOrder: {},
    };
  },
  watch: {
    selectedCustomerId() {
      this.currentPage = 1;
      this.changeView();
    },
    selectedView() {
      if(this.firstLoad) {
        //console.log('firstLoad', this.selectedView)
        this.firstLoad = false;
      } else {
        this.resetFilters({ shouldTriggerChangeView: false });
      }
      //Reset the current page when the Get Started View is changed
      this.currentPage = 1;
      if(this.selectedView !==  "") {
        this.selectedViewObject = this.views.find(
          (view) => view.name === this.selectedView
        );
      } else {
        this.selectedViewObject = {}
      }
    },
    localOrder: {
      handler() {
        // TODO: double test this force closing
        // doing force close update modal
        this.$root.$emit("bv::hide::modal", "update-grade-modal");
        this.$root.$emit("bv::hide::modal", "update-modal");
      },
    },
    selectedOrder() {
      if (this.selectedOrder && this.selectedOrder.customer && this.selectedOrder.customer._id) {
        this.$store
          .dispatch("fetchRelatedOrders", this.selectedOrder.customer._id)
          .then(() => {
            // console.log("fetched related", res);
          })
          .catch(() => {
            // console.log("error fetching related", err);
          });
      }
    },
    selectedPartner() {
      //console.log("SELECTED PARTNER")
      if (!this.selectedPartner) return;
      this.lastFilteredCustomers = [] 
      //Clear selected customer ID
      // this.selectedCustomerId = "";
      //Fetch the customers for the partner
      this.$store
        .dispatch("fetchCustomers")
        .then(() => {
          this.handleCustomersSearch();
          // findings: does this run every time you fetch a new partner. Different for admin vs partner? It does not change customers (for admin).
          // this.customersResultLimit = this.customers?.length <= 1500 ? this.customers.length : 0;
          //console.log("dashCustomers", res);
        })
        .catch(() => {
          // console.log("dashCustomers", err);
        });
      //Clear the selected rows
      this.clearSelection();

      //TEMP fix for UOP admin selectedView not updating
      if (!this.orderQueryString) {
        this.setDefaultViews();
      }

      // Fetch updated views_count on partner change
      if(!this.$route.query.objectId) this.getViewsCountQuery();

      //Fetch the partners orders
      this.$store
        .dispatch("fetchOrders", this.orderQueryString)
        .then((res) => {
          //console.log("resDash", res);

          //If query string includes objectId
          if(this.$route.query.objectId) {
            //Check if response includes any order
            if(this.$route.query.objectId && res.payload && res.payload.content.length > 0 ) {

              this.selectRow(0)
            } else {

              this.$router.replace({'query': null});
              this.$router.go()
            }
          }
        })
        .catch(() => {

          //this.$router.replace({'query': null});
          //this.$router.go()
          //console.log("errDash", err);
        });
    },
    // selectedCustomerId() {
    //   this.currentPage = 1;
    //   this.changeView();
    // },
  },

  computed: {
    selectedCustomerLabel() {
      const selectedCustomer = this.lastFilteredCustomers.find(
        (customer) => customer._id === this.selectedCustomerId
      );
      return selectedCustomer ? selectedCustomer.option_label : 'no selectedcustomer text';
    },
    noCustomerResultsMessage() {
      return this.customers?.length === 0
        ? "Fetching students..." // add loading spinner
        : this.searchTextInCustomerFilter?.length < 4
          ? "Enter at least 4 characters to find students"
          : `No student has a first name, last name, email, or DOB starting with "${this.searchTextInCustomerFilter}"`
    },
    orders() {
      return this.$store.getters.orders;
    },
    relatedOrders() {
      return this.$store.state.relatedOrders;
    },
    totalOrders() {
      return this.$store.state.totalOrders;
    },
    totalPages() {
      if (!this.totalOrders) {
        return 0;
      }
      return Math.ceil(this.totalOrders / this.pageSize);
    },
    partners() {
      return this.$store.state.partners;
    },
    customers() {
      // return this.$store.state.customers;
      return this.$store.state.customers || [];
    },
    selectedPartner() {
      if (this.$store.state.user.user_role == "uop_admin") 
        return { partner_id: -1, partner_name: "All Partners" };

      return (
        this.partners.find(
          (partner) => partner.partner_id === this.$store.state.user.partnerId
        ) || false
      );
    },
    orderQueryString() {
      var queryString;
      if(!this.$route.query || !this.$route.query.objectId) {
        //console.log("no objectId")
        const params = this.views.find(
          (view) => view.name === this.selectedView
        )?.params;
        if (!params) return;
        queryString = Object.keys(params)
          .map((key) => `${key}=${params[key]}`)
          .join("&");
        //console.log(queryString);
        if (this.selectedCustomerId)
          queryString += `&customer=${this.selectedCustomerId}`;
        queryString += `&page_size=${this.pageSize}&page_offset=${this.currentPage-1}`;
        if (this.filterDateField) {
          queryString += `&end_date=${this.end_date}&start_date=${this.start_date}&date_key=${this.filterDateField}`;
        }
        if (this.filterFieldKey !== "" && this.filterFieldValue !== "")  {
          queryString += `&${this.filterFieldKey}=${this.filterFieldValue}`;
        }
        //console.log("queryString 1",queryString)
      } else {
        queryString = "objectId="+this.$route.query.objectId
      }
      if(this.$store.state.user && this.$store.state.user.user_role) {
        queryString += `&user_role=${this.$store.state.user.user_role}`;
      } else {
        queryString += `&user_role=partner`;
      }
      return queryString;
    },
    selectedOrder: {
      get: function () {
        // console.log({selectedRows: this.selectedRows, currentModalCount: this.currentModalCount, orders: this.orders})
        if (this.selectedRows.length < 1) return {};
        return this.orders[this.selectedRows[this.currentModalCount]];
      },
      set: function () {},
    },
    relatedNotDeletedRecords() {
      return this.relatedOrders.filter((order) => !order.deleted_status);
    },
    relatedCompletedRecords() {
      return this.relatedOrders.filter(
        (order) => !order.deleted_status && !!order.course_status
      );
    },
    relatedDuplicateOrders() {
      return this.relatedOrders.filter(
        (order) => !order.deleted_status && !!order.duplicate_status
      );
    },
    relatedDeletedRecords() {
      return this.relatedOrders.filter((order) => order.deleted_status);
    },
    relatedInProgress() {
      return this.relatedOrders.filter(
        (order) => !order.course_status && !order.deleted_status
      );
    },
    currentPageInput: {
      get: function () {
        return this.currentPage;
      },
      set: function (data) {
        if (data >= this.totalPages) {
          this.currentPage = this.totalPages;
        } else {
          this.currentPage = data;
        }
        this.selectedRows = [];
        this.changeView();
        return data;
      },
    },
    showSelectedActions() {
      //Init an array of views where the selected actions should not show
      const noShowViews = [
        "View In-Progress Courses",
        "View Completed Courses",
        "View Deleted Registrations",
        "View Graded Courses Via Banner",
        "View Registrations Entered Via Banner",
        "View Unvalidated Registrations",
        "View Refunded Registrations",

      ];
      //If current view is in that array return false
      if (noShowViews.includes(this.selectedView)) return false;
      //Otherwise return true
      return true;
    },
    showValidateAction() {
      //Init an array of views where the selected actions should no show
      const ShowViews = ["View Unvalidated Registrations"];
      //If current view is in that array return true
      if (ShowViews.includes(this.selectedView)) return true;
      //Otherwise return false
      return false;
    },
  },
  methods: {
    handleCustomersSearch(searchText) {
      this.searchTextInCustomerFilter = searchText || this.searchTextInCustomerFilter;  // Update the data property, reset to empty string if needed
      this.updateLastFilteredCustomers(); // Call the update method
    },
    updateLastFilteredCustomers() {
      this.lastFilteredCustomers = this.searchTextInCustomerFilter?.length >= 4
        ? this.customerSearchBarFilter()
        : this.customers?.length <= this.customersResultLimit
          ? this.customers
          : this.lastFilteredCustomers; // Retain existing results if neither condition is met
    },
    customerSearchBarFilter() {
      const search = this.searchTextInCustomerFilter?.toLowerCase();

      const results = this.customers?.filter(customer => {
        const terms = this.generateSearchTerms(customer.option_label);
        return terms.some(term => term.startsWith(search));
      });

      return results
    },
    generateSearchTerms(option_label) {

      const searchTerms = option_label
        ?.toLowerCase()
        ?.split(' ')
        ?.filter(searchTerm => !['dob:', 'email(s):'].includes(searchTerm))

        // Add permutations of searchTerms
        let firstLast,
            lastFirst,
            firstDob,
            lastDob,
            dobFirst,
            dobLast,
            firstLastDob,
            lastFirstDob,
            dobFirstLast,
            dobLastFirst,
            firstEmail,
            lastEmail,
            firstLastEmail,
            lastFirstEmail,
            emailDob,
            dobEmail

        const first = searchTerms?.[0]
        const last = searchTerms?.[1]
        const dob = searchTerms?.[2]
        const email = searchTerms?.[3]

        if (last) {
          firstLast = first + ' ' + last
          lastFirst = last + ' ' + first
          searchTerms.push(firstLast)
          searchTerms.push(lastFirst)

          if (dob) {
            firstDob = first + ' ' + dob
            lastDob = last + ' ' + dob
            dobFirst = dob + ' ' + first
            dobLast = dob + ' ' + last
            firstLastDob = first + ' ' + last + ' ' + dob
            lastFirstDob = last + ' ' + first + ' ' + dob
            dobFirstLast = dob + ' ' + first + ' ' + last
            dobLastFirst = dob + ' ' + last + ' ' + first
            searchTerms.push(firstDob)
            searchTerms.push(lastDob)
            searchTerms.push(dobFirst)
            searchTerms.push(dobLast)
            searchTerms.push(firstLastDob)
            searchTerms.push(lastFirstDob)
            searchTerms.push(dobFirstLast)
            searchTerms.push(dobLastFirst)
          }

            if (email) {
              firstEmail = first + ' ' + email
              lastEmail = last + ' ' + email
              firstLastEmail = first + ' ' + last + ' ' + email
              lastFirstEmail = last + ' ' + first + ' ' + email
              emailDob = email + ' ' + dob
              dobEmail = dob + ' ' + email
              searchTerms.push(firstEmail)
              searchTerms.push(lastEmail)
              searchTerms.push(firstLastEmail)
              searchTerms.push(lastFirstEmail)
              searchTerms.push(emailDob)
              searchTerms.push(dobEmail)
            }
        }

        return searchTerms
    },
    disableActionDropdown() {
      const dropdown = document.getElementById("bulkActionDropdown")
      const dropdownLength = (dropdown && dropdown !== null) ? (dropdown.getElementsByTagName("li").length) : 0
      //console.log("dropdownLength", dropdownLength)
      return (dropdownLength == 0);
    },
    getAvatarInitial() {
      return (this.$store.state.user && this.$store.state.user.name) ? this.$store.state.user.name.charAt(0) : " ";
    },
    reloadDashboard() {

      //console.log("clear query")
      this.$router.replace({'query': null});
      this.$router.go()
    },
    async handleDownloadExport() {
      try {
        let res = await this.$store.dispatch(
          "downloadExport",
          this.orderQueryString
        );
        if (res.success) {
          let title = `${this.selectedView} UOP Record`;
          if (this.filterDateField) {
            let fieldName = this.filterDateField
              ?.replaceAll("_", " ")
              .split(" ")
              ?.map((each) => each.charAt(0).toUpperCase() + each.slice(1))
              .join(" ");
            title =
              title +
              ` - ${fieldName} Filter ${this.start_date} - ${this.end_date}`;
          }
          download(res.payload, title, "text/csv");
          this.makeToast({
            message: "Successfully Exported Records",
            variant: "success",
          });
        }
      } catch (err) {
        this.makeToast({
          message: "Error: Failed to Export Records",
          variant: "danger",
        });
      }
    },
    handleFilter(action) {
      if (typeof action === "function") action();
      this.changeView();
    },
    checkIfAllowed(payload = []) {
      const userRole =
        this.$store.state.user && this.$store.state.user.user_role;
      return payload.includes(userRole);
    },
    checkIfCurrentViewMatch(payload = []) {
      if (payload.includes(this.selectedView)) return true;
      return false;
    },
    checkIfPartnerMatch(payload) {
      const userRole =
        this.$store.state.user && this.$store.state.user.user_role;
      const partners =
        this.$store.state.user.partners && this.$store.state.user.partners;
      let result = false
      // console.log({result, partners, userRole})

      if (
        partners &&
        partners.length &&
        payload.length &&
        userRole === "partner"
      ) {
        result = partners.some((each) => payload.includes(each));
      }

      return result;
    },
    resetFilters({ type, shouldTriggerChangeView = true, action }) {

      if(!type || type == 'date') {
        this.start_date = "";
        this.end_date = "";
        this.filterDateField = "";
      }
      if(!type || type == 'field') {
        this.filterFieldKey = "";
        this.filterFieldValue = "";
      }
      if (action) action();
      if (shouldTriggerChangeView) {
        this.changeView();
      }
    },
    handleFilterDateField(newValue) {
      this.filterDateField = newValue;
    },
    dateHandler({ field, data }) {
      if (!field) return;
      this[field] = data.selectedYMD;
    },
    async getViewsCountQuery() {
      const params = {
        views: this.getViews(),
        ...{
          ...(this.$store.state.user &&
          Array.isArray(this.$store.state.user.partners)
            ? {
                partners:
                  this.$store.state.user && this.$store.state.user.partners,
              }
            : ""),
        },
        ...{
          ...(this.$store.state.user && this.$store.state.user.partnerId === -1
            ? ""
            : { partner_id: this.$store.state.user.partnerId }),
        },
        user_role: this.$store.state.user && this.$store.state.user.user_role,
      };
      try {
        await this.$store.dispatch("getViewsCount", params);
        // console.log('Response view_count: ',viewCount)
      } catch (err) {
        // console.log('Error view_count: ', err)
      }

      // return {views: JSON.stringify(this.getViews()), partners: this.$store.user.partners, partner_id: this.$store.user.partnerId };
    },
    handleCountSelectChange() {
      this.orderIndexEnd = this.pageSize;
      this.changeView();
    },
    getViews() {
      //If no user or role return empty array
      if (!this.$store.state.user || !this.$store.state.user.user_role ) return [];
      //If admin user return all views
      if (this.$store.state.user.user_role == "admin") return this.views;
      //Otherwise restrict views to user role
      var restricted_views = [];

      for (const view of this.views) {
        //If view includes user's role
        if ( (view.user_roles).includes(this.$store.state.user.user_role) ) {
          //If parter and unvalidated view apply logic based on user's partners
          if (this.$store.state.user.user_role == "partner" && view.name === this.VIEWS.UNVALIDATED_REGISTRATIONS) {
            let currentPartners = this.$store.state.partners;
            let currentPartner = this.$store.state.user.partnerId;
            let isMatched = currentPartners.filter(
              (e) => e.partner_id === currentPartner
            );
            if (isMatched.length && isMatched[0].partner_id !== -1) {
              if (isMatched[0].requires_validation) {
                restricted_views.push(view);
              }
            }
          //If parter and in-progress LD view apply logic based on user can_view_ld
          } else if (this.$store.state.user.user_role == "partner" && view.name === this.VIEWS.IN_PROGRESS_LD) {
            if(typeof this.$store.state.user.can_view_ld !== "undefined" && this.$store.state.user.can_view_ld) restricted_views.push(view);
          //Otherwise just push the view
          } else {
            restricted_views.push(view);
          }
        }
      }
      return restricted_views;
    },
    changeView() {
      if(this.$route.query.objectId) {
        this.$router.go()
        return;
      }
      this.selectedViewObject = this.views.find(
        (view) => view.name === this.selectedView
      );
      this.getViewsCountQuery()

      var oldSelectedOrderId;
      if(this.selectedRows.length == 1) {
        oldSelectedOrderId = this.orders[this.selectedRows[0]]._id
      }
      
      this.selectedRows = []
      
      this.$store
        .dispatch("fetchOrders", this.orderQueryString)
        .then(() => {
          if(oldSelectedOrderId) {
            const index = this.orders.findIndex(order => order._id === oldSelectedOrderId);
            if(index !== -1) this.selectRow(index)
          }
         //console.log("resDash", res);
        })
        .catch(() => {
          //console.log("errDash", err);
        });
    },
    prevPage() {
      if (this.currentPage === 1) return;
      this.currentPage--;
      this.changeView();
    },
    nextPage() {
      if (this.currentPage === this.totalPages) return;
      this.currentPage++;
      this.changeView();
    },
    selectRow(row) {
      if (this.selectedRows.includes(row)) {
        this.selectedRows = this.selectedRows.filter((r) => r !== row);
      } else {
        this.selectedRows.push(row);
      }
    },
    clearSelection() {
      this.selectedRows = [];
    },
    closeDialog() {
      this.modalStage = "";
      this.modalMessage = "";
      this.currentModalCount = 0;
    },
    async deleteSelectedOrders() {
      const ordersToDelete = this.selectedRows.map((row) => this.orders[row]);
      for (const order of ordersToDelete) {
        if (order.order_status) {
          this.makeToast({
            message: `Can't delete order ${order.order} - ${order.course ? order.course.course_number : ''} because it has
            already been processed`,
            variant: "danger",
          });
          continue;
        }
        const res = await this.$store.dispatch("deleteOrder", order._id);
        if (res.success)
          this.makeToast({
            message: res.payload.message,
            variant: "success",
          });
        else this.makeToast({ message: res.payload.message || "" });
      }
      this.clearSelection();
      this.changeView();
    },
    async processSelectedOrders() {
      const ordersToProcess = this.selectedRows.map((row) => this.orders[row]);
      for (const order of ordersToProcess) {
        if (order.order_status) {
          this.makeToast({
            variant: "danger",
            message: `Can't process order ${order.order} - ${order.course ? order.course.course_number : ""} because it has already been processed`,
          });
          continue;
        }
        if (order.duplicate_status) {
          this.makeToast({
            variant: "danger",
            message: `Can't process order ${order.order} - ${order.course ? order.course.course_number : ""} because it is a duplicate`,
          });
          continue;
        }
        const res = await this.$store.dispatch("sendPutRequest", {
          endpointPath: "orders/"+order._id+"/process"
        });
        if (res.success) {
          this.makeToast({ message: res.payload.message, variant: "success" });
        } else this.makeToast({ message: res.payload, variant: "danger" });
      }
      this.clearSelection();
      this.changeView();
    },
    clearBulkModalSelection({reload = true}) {
      this.modalStage = ""; // It will close modal at end
      this.currentModalCount = 0;
      this.nextModal = null;
      this.selectedOrder = null;
      if(reload) this.changeView();
    },
    confirmDeleteOrder() {
      this.modalStage = "processing";
      this.$store
        .dispatch("deleteOrder", this.selectedOrder._id)
        .then((res) => {
          // console.log("resDelete", res);
          // this.changeView();
          this.makeToast({
            variant: "success",
            message: res.payload.message,
          });
          if (!this.nextModal) {
            this.closeDialog();
            this.$root.$emit("bv::hide::modal", "delete-modal");
            this.deleteModalTitle = "Delete Order Confirmation";
            this.changeView();
          } else {
            this.handleNextModal({ modalStage: "success", type: "deleteBulk" });
          }
        })
        .catch((err) => {
          if (!this.nextModal) {
            this.closeDialog();
            this.$root.$emit("bv::hide::modal", "delete-modal");
            this.deleteModalTitle = "Delete Order Confirmation";
          } else {
            this.handleNextModal({ modalStage: "error", type: "deleteBulk" });
          }
          this.makeToast({ variant: "danger", message: err.payload });
        });
    },
    // This function will handle next modal calls and force close and custom actions passed from modals
    handleNextModal(payload) {
      // payload action is set when modal is forceClosed
      // console.log(payload)
      if (
        this.selectedRows.length > 1 &&
        this.nextModal &&
        !(payload && payload.action === "forceCancel")
      ) {
        this.currentModalCount++;
        this.selectedOrder = this.nextModal;
        if (payload.type === "deleteBulk") {
          this.deleteSelectedOrdersModal(payload);
        } else if (payload.type === "processBulk") {
          this.processSelectedOrdersModal(payload);
        } else if (payload.type === "validateBulk") {
          this.validateSelectedOrdersModal(payload);
        } else if (payload.type === "bannerEnterBulk") {
          this.registerIntoBannerSelectedOrdersModal(payload);
        } else if (payload.type === "bannerGradesBulk") {
          this.gradesIntoBannerSelectedOrdersModal(payload);
        }
        this.modalStage = "";
      } else if (typeof payload.action === "function") {
        if (this.modalMode) {
          payload.action();
          this.clearBulkModalSelection({ reload: false });
          this.modalMode = ''
          return;
        }
        payload.action();
        this.clearBulkModalSelection();
      } else if (payload && payload.action === "forceCancel") {
        if (this.modalMode) {
          this.clearBulkModalSelection({ reload: false });
          this.modalMode = ''
          return;
        }
        this.clearBulkModalSelection({reload: true});
      }
    },
    confirmProcessOrder() {
      if(this.modalStage == 'processing') {
        this.makeToast({
          variant: "danger",
          message: 'Action Blocked. It seems you are clicking to fast.',
        });
        return 
      }
      this.modalStage = "processing";
      this.$store
        .dispatch("sendPutRequest", {
          endpointPath: "orders/"+this.selectedOrder._id+"/process"
        })
        .then((res) => {
          this.makeToast({
            variant: "success",
            message: res.payload.message,
          });
          if (!this.nextModal) {
            this.closeDialog();
            this.$root.$emit("bv::hide::modal", "process-modal");
            this.proccessModalTitle = "Process Payment Confirmation";
            this.changeView();
          } else {
            this.handleNextModal({
              modalStage: "success",
              type: "processBulk",
            });
          }
        })
        .catch((err) => {
          if (!this.nextModal) {
            this.closeDialog();
            this.$root.$emit("bv::hide::modal", "process-modal");
            this.proccessModalTitle = "Process Payment Confirmation";
          } else {
            this.handleNextModal({ modalStage: "error", type: "processBulk" });
          }
          this.makeToast({
            variant: "danger",
            message: err.payload || "Something went wrong",
          });
        });
    },
    confirmBannerEnter() {
      this.modalStage = "processing";
      this.$store
        .dispatch("sendPutRequest", {
          endpointPath: "orders/"+this.selectedOrder._id+"/banner_enter"
        })
        .then((res) => {
          this.makeToast({
            variant: "success",
            message: res.payload.message,
          });
          if (!this.nextModal) {
            this.changeView();
            this.closeDialog();
            this.$root.$emit("bv::hide::modal", "banner-enter-modal");
            this.enterIntoBannerModalTitle =
              "Are you sure you want to mark this course Entered into Banner?";
            this.modalStage = "success";
          } else {
            this.handleNextModal({
              modalStage: "error",
              type: "bannerEnterBulk",
            });
          }
        })
        .catch((err) => {
          if (!this.nextModal) {
            this.modalStage = "error";
            this.closeDialog();
            this.changeView();
            this.$root.$emit("bv::hide::modal", "banner-enter-modal");
            this.enterIntoBannerModalTitle =
              "Are you sure you want to mark this course Entered into Banner?";
          } else {
            this.handleNextModal({
              modalStage: "error",
              type: "bannerEnterBulk",
            });
          }
          this.makeToast({
            variant: "danger",
            message: err.payload,
          });
        });
    },
    confirmBannerGrade() {
      this.modalStage = "processing";
      this.$store
        .dispatch("sendPutRequest", {
          endpointPath: "orders/"+this.selectedOrder._id+"/banner_grade"
        })
        .then((res) => {
          this.makeToast({ message: res.message, variant: "success" });
          if (!this.nextModal) {
            this.changeView();
            this.closeDialog();
            this.$root.$emit("bv::hide::modal", "update-grade-modal");
            this.gradesIntoBannerModalTitle =
              "Are you sure you want to mark this course as Graded via Banner at UOP?";
            this.modalStage = "success";
          } else {
            this.handleNextModal({
              modalStage: "success",
              type: "bannerGradesBulk",
            });
          }
        })
        .catch((err) => {
          if (!this.nextModal) {
            this.changeView();
            this.closeDialog();
            this.$root.$emit("bv::hide::modal", "update-grade-modal");
            this.gradesIntoBannerModalTitle =
              "Are you sure you want to mark this course as Graded via Banner at UOP?";
            this.modalStage = "error";
          } else {
            this.handleNextModal({
              modalStage: "error",
              type: "bannerGradesBulk",
            });
          }
          this.makeToast({ message: err.payload, variant: "danger" });
        });
    },
    confirmRefund() {
      this.modalStage = "processing";
      this.$store
        .dispatch("refundOrder", this.selectedOrder._id)
        .then((res) => {
          this.makeToast({ message: res.message, variant: "success" });
          if (!this.nextModal) {
            this.changeView();
            this.closeDialog();
            this.$root.$emit("bv::hide::modal", "refund-modal");
            this.modalStage = "success";
          }
        })
        .catch((err) => {
          if (!this.nextModal) {
            this.changeView();
            this.closeDialog();
            this.$root.$emit("bv::hide::modal", "refund-modal");
            this.modalStage = "error";
          }
          this.makeToast({ message: err.message, variant: "danger" });
        });
    },
    confirmSendResubmit() {
      this.modalStage = "processing";
      this.$store
        .dispatch("sendResubmitOrder", this.selectedOrder._id)
        .then((res) => {
          this.makeToast({ message: res.message, variant: "success" });
          if (!this.nextModal) {
            this.changeView();
            this.closeDialog();
            this.$root.$emit("bv::hide::modal", "send-resubmit-modal");
            this.modalStage = "success";
          }
        })
        .catch((err) => {
          if (!this.nextModal) {
            this.changeView();
            this.closeDialog();
            this.$root.$emit("bv::hide::modal", "send-resubmit-modal");
            this.modalStage = "error";
          }
          this.makeToast({ message: err.message, variant: "danger" });
        });
    },
    confirmUpdateOrder(updateObj) {
      this.modalStage = "processing";
      updateObj = updateObj
        ? updateObj
        : this.localOrder
        ? this.localOrder.payload
        : updateObj;
      this.$store
        .dispatch("updateOrder", updateObj)
        .then((res) => {
          this.modalStage = "success";
          this.localOrder = {};
          if(this.studentIdChanged && this.studentIdChanged.changed){
            this.makeToast({ message: `UOP Student ID for “${this.studentIdChanged.full_name}” has been updated on their records`, variant: "success" });
            this.studentIdChanged = {}
          }else{
            this.makeToast({ message: res.payload.message, variant: "success" });
          }
          //this.changeView();
          this.$store
            .dispatch("fetchCustomers")
            .then((res) => {
              // this.customersResultLimit = this.customers?.length <= 1500 ? this.customers.length : 0;
              //Check if selected cystomer ID is no longer in customers
              if(this.selectedCustomerId !== "" && res.payload && !(res.payload.content).find(o => o._id === this.selectedCustomerId)) {
                this.selectedCustomerId = ""
              } else {
                this.changeView();
              }
              //console.log("dashCustomers", res);
            })
            .catch(() => {
              // console.log("dashCustomers", err);
            });
        })
        .catch((err) => {
          this.studentIdChanged = {}
          this.modalStage = "error";
          this.makeToast({ message: err.payload, variant: "danger" });
        });
    },
    confirmValidateOrder() {
      this.modalStage = "processing";
      this.$store
        .dispatch("sendPutRequest", {
          endpointPath: "orders/"+this.selectedOrder._id+"/validate"
        })
        .then((res) => {
          this.makeToast({ message: res.payload.message, variant: "success" });
          if (!this.nextModal) {
            this.closeDialog();
            this.$root.$emit("bv::hide::modal", "validate-modal");
            this.validationModalTitle = "Click CONFIRM to Validate Course";
            this.changeView();
          } else {
            this.handleNextModal({
              modalStage: "success",
              type: "validateBulk",
            });
          }
        })
        .catch((err) => {
          this.makeToast({ message: err.payload, variant: "danger" });
          if (!this.nextModal) {
            this.closeDialog();
            this.$root.$emit("bv::hide::modal", "validate-modal");
            this.validationModalTitle = "Click CONFIRM to Validate Course";
            this.changeView();
          } else {
            this.handleNextModal({
              modalStage: "success",
              type: "validateBulk",
            });
          }
        });
    },
    deleteSelectedOrdersModal(payload) {
      let modalTitle = "";
      let currentModalCount = this.currentModalCount + 1;
      let currentModalSelectedRows = [];
      if (this.selectedRows.length) {
        currentModalSelectedRows = this.selectedRows.filter(
          (each) => !this.orders[each].order_status
        );
        if (!currentModalSelectedRows.length && this.selectedRows.length > 1)
          return this.makeToast({
            variant: "danger",
            message: "None of the selected orders are eligible for deletion.",
          });

        if (
          this.selectedRows.length === 1 &&
          currentModalSelectedRows.length === 0
        )
          return this.makeToast({
            variant: "danger",
            message: "The selected order is not eligible to be deleted",
          });

        if (this.selectedRows.length !== currentModalSelectedRows.length)
          return this.makeToast({
            variant: "danger",
            message: "One of the selected orders is not eligible to be deleted",
          });
      }
      if (currentModalSelectedRows.length > 1) {
        currentModalCount = `${currentModalCount} / ${currentModalSelectedRows.length}`;
        modalTitle = `${currentModalCount} - Delete Order Confirmation`;
        this.deleteModalTitle = modalTitle;
        this.nextModal =
          this.orders[currentModalSelectedRows[this.currentModalCount + 1]];
      } else if (payload && typeof payload.action === "function") {
        // closing modal if its last cancel attempt
        payload.action();
        this.deleteModalTitle = "Delete Order Confirmation";
      }
      this.$root.$emit("bv::show::modal", "delete-modal");
    },

    deleteSelectedSingleOrderModal(mode) {
      // fix multiple are selcted and wanted to delete single record
      // Todo: test multiple select single delete and click cancel behavior
      this.modalMode = mode;
      this.deleteModalTitle = "Delete Order Confirmation";
      this.$root.$emit("bv::show::modal", "delete-modal");
    },
    processSelectedOrdersModal(payload) {
      let modalTitle = "";
      let currentModalCount = this.currentModalCount + 1;
      // if greater then 1 means there are multiple records it should enter to bulk processing
      let currentModalSelectedRows = [];
      if (this.selectedRows.length) {
        currentModalSelectedRows = this.selectedRows.filter(
          (each) =>
            !this.orders[each].duplicate_status &&
            !this.orders[each].order_status
        );

        if (!currentModalSelectedRows.length && this.selectedRows.length > 1)
          return this.makeToast({
            variant: "danger",
            message: "None of the selected orders are eligible for processing.",
          });

        if (
          this.selectedRows.length === 1 &&
          currentModalSelectedRows.length === 0
        )
          return this.makeToast({
            variant: "danger",
            message: "The selected order is not eligible for processing",
          });

        if (this.selectedRows.length !== currentModalSelectedRows.length)
          return this.makeToast({
            variant: "danger",
            message:
              "One of the selected orders is not eligible for processing",
          });
      }
      if (currentModalSelectedRows.length > 1) {
        currentModalCount = `${currentModalCount} / ${currentModalSelectedRows.length}`;
        modalTitle = `${currentModalCount} - Process Payment Confirmation`;
        this.proccessModalTitle = modalTitle;
        this.nextModal =
          this.orders[currentModalSelectedRows[this.currentModalCount + 1]];
      } else if (payload && typeof payload.action === "function") {
        // closing modal if its last cancel click
        payload.action();
        this.proccessModalTitle = "Process Payment Confirmation";
      } else if (currentModalSelectedRows.length === 1) {
        this.proccessModalTitle = "Process Payment Confirmation";
        this.currentModalCount = this.selectedRows.findIndex(
          (each) => each == currentModalSelectedRows[0]
        );
      }
      this.$root.$emit("bv::show::modal", "process-modal");
    },

    processSelectedSingleOrderModal(mode) {

      // Todo: test multiple select single delete and click cancel behavior
      this.modalMode = mode
      this.$root.$emit("bv::show::modal", "process-modal");
    },

    confimValidateOrderModal(mode) {
      this.modalMode = mode
        this.validationModalTitle = "Click CONFIRM to Validate Course"
        this.$root.$emit("bv::show::modal", "validate-modal");
    },
    validateSelectedOrdersModal(payload) {
      let modalTitle = "";
      let currentModalCount = this.currentModalCount + 1;
      // if greater then 1 means there are multiple records it should enter to bulk processing
      let currentModalSelectedRows = [];
      if (this.selectedRows.length) {
        currentModalSelectedRows = this.selectedRows.filter(
          (each) => !this.orders[each].duplicate_status
        );

        if (!currentModalSelectedRows.length && this.selectedRows.length > 1)
          return this.makeToast({
            variant: "danger",
            message: "None of the selected orders are eligible for validation.",
          });

        if (
          this.selectedRows.length === 1 &&
          currentModalSelectedRows.length === 0
        )
          return this.makeToast({
            variant: "danger",
            message: "The selected order is not eligible for validation.",
          });

        if (this.selectedRows.length !== currentModalSelectedRows.length)
          return this.makeToast({
            variant: "danger",
            message:
              "One of the selected orders is not eligible for validation.",
          });
      }
      if (currentModalSelectedRows.length > 1) {
        currentModalCount = `${currentModalCount} / ${currentModalSelectedRows.length}`;
        modalTitle = `${currentModalCount} - Click CONFIRM to Validate Course`;
        this.validationModalTitle = modalTitle;
        this.nextModal =
          this.orders[currentModalSelectedRows[this.currentModalCount + 1]];
      } else if (payload && typeof payload.action === "function") {
        // closing modal if its last cancel click
        payload.action();
        this.validationModalTitle = "Click CONFIRM to Validate Course";
      } else if (currentModalSelectedRows.length === 1) {
        this.validationModalTitle = "Click CONFIRM to Validate Course";
        this.currentModalCount = this.selectedRows.findIndex(
          (each) => each == currentModalSelectedRows[0]
        );
      }
      this.$root.$emit("bv::show::modal", "validate-modal");
    },
    registerIntoBannerSelectedOrdersModal(payload) {
      let modalTitle = "";
      let currentModalCount = this.currentModalCount + 1;
      // if greater then 1 means there are multiple records it should enter to bulk processing
      let currentModalSelectedRows = [];
      if (this.selectedRows.length) {
        currentModalSelectedRows = this.selectedRows.filter(
          (each) => !this.orders[each].duplicate_status
        );

        if (!currentModalSelectedRows.length && this.selectedRows.length > 1)
          return this.makeToast({
            variant: "danger",
            message: "None of the selected orders are eligible for action.",
          });

        if (
          this.selectedRows.length === 1 &&
          currentModalSelectedRows.length === 0
        )
          return this.makeToast({
            variant: "danger",
            message: "The selected order is not eligible for action.",
          });

        if (this.selectedRows.length !== currentModalSelectedRows.length)
          return this.makeToast({
            variant: "danger",
            message: "One of the selected orders is not eligible for action.",
          });
      }
      if (currentModalSelectedRows.length > 1) {
        // if `currentModalSelectedRows` length is greater then 1, it means they are bulk
        // and need to be handled in this condition
        currentModalCount = `${currentModalCount} / ${currentModalSelectedRows.length}`;
        modalTitle = `${currentModalCount} - Are you sure you want to mark this course Entered into Banner?`;
        this.enterIntoBannerModalTitle = modalTitle;
        this.nextModal =
          this.orders[currentModalSelectedRows[this.currentModalCount + 1]];
      } else if (payload && typeof payload.action === "function") {
        // closing modal if its last cancel click
        payload.action();
        this.enterIntoBannerModalTitle =
          "Are you sure you want to mark this course Entered into Banner?";
      } else if (currentModalSelectedRows.length === 1) {
        // if single Item in filtered `currentModalSelectedRows` then reset title
        this.enterIntoBannerModalTitle =
          "Are you sure you want to mark this course Entered into Banner?";
        this.currentModalCount = this.selectedRows.findIndex(
          (each) => each == currentModalSelectedRows[0]
        );
      }
      this.$root.$emit("bv::show::modal", "banner-enter-modal");
    },
    registerIntoBannerSelectedSingleModal(mode) {
      this.modalMode = mode
      this.enterIntoBannerModalTitle =
        "Are you sure you want to mark this course Entered into Banner?";
      this.$root.$emit("bv::show::modal", "banner-enter-modal");
    },
    gradesIntoBannerSelectedOrdersModal(payload) {
      let modalTitle = "";
      let currentModalCount = this.currentModalCount + 1;
      // if greater then 1 means there are multiple records it should enter to bulk processing
      let currentModalSelectedRows = [];
      if (this.selectedRows.length) {
        currentModalSelectedRows = this.selectedRows.filter(
          (each) =>
            !(
              this.orders &&
              this.orders[each] &&
              this.orders[each].duplicate_status
            )
        );

        if (!currentModalSelectedRows.length && this.selectedRows.length > 1)
          return this.makeToast({
            variant: "danger",
            message: "None of the selected orders are eligible for action.",
          });

        if (
          this.selectedRows.length === 1 &&
          currentModalSelectedRows.length === 0
        )
          return this.makeToast({
            variant: "danger",
            message: "The selected order is not eligible for action.",
          });

        if (this.selectedRows.length !== currentModalSelectedRows.length)
          return this.makeToast({
            variant: "danger",
            message: "One of the selected orders is not eligible for action.",
          });
      }
      if (currentModalSelectedRows.length > 1) {
        // if `currentModalSelectedRows` length is greater then 1, it means they are bulk
        // and need to be handled in this condition
        currentModalCount = `${currentModalCount} / ${currentModalSelectedRows.length}`;
        modalTitle = `${currentModalCount} - Are you sure you want to mark this course as Graded via Banner at UOP?`;
        this.gradesIntoBannerModalTitle = modalTitle;
        this.nextModal =
          this.orders[currentModalSelectedRows[this.currentModalCount + 1]];
      } else if (payload && typeof payload.action === "function") {
        // closing modal if its last cancel click
        payload.action();
        this.gradesIntoBannerModalTitle =
          "Are you sure you want to mark this course as Graded via Banner at UOP?";
      } else if (currentModalSelectedRows.length === 1) {
        // if single Item in filtered `currentModalSelectedRows` then reset title
        this.gradesIntoBannerModalTitle =
          "Are you sure you want to mark this course as Graded via Banner at UOP?";
        this.currentModalCount = this.selectedRows.findIndex(
          (each) => each == currentModalSelectedRows[0]
        );
      }
      this.$root.$emit("bv::show::modal", "banner-grade-modal");
    },
    gradesIntoBannerSelectedSingleModal(mode) {
      this.modalMode = mode
      this.gradesIntoBannerModalTitle =
        "Are you sure you want to mark this course as Graded via Banner at UOP?";
      this.$root.$emit("bv::show::modal", "banner-grade-modal");
    },
    refundSelectedSingleOrderModal(mode) {
      this.modalMode = mode
      this.$root.$emit("bv::show::modal", "refund-modal");
    },
    sendResubmitSelectedSingleOrderModal(mode) {
      this.modalMode = mode
      this.$root.$emit("bv::show::modal", "send-resubmit-modal");
    },
    viewCourseAssignments() {
      this.$root.$emit("bv::show::modal", "assignments-modal");
    },
    handleGradeOrderUpdate(orderObj) {
      let isModalRequire = this.checkIfItneedsModal(orderObj);
      if (isModalRequire) {
        this.updateOrderTitle = orderObj.grade_type
          ? "UOP Banner Grading"
          : "InsidePacific Grading";
        // setting payload so that it get passed to confirmUpdateOrder incase of modals opening
        this.localOrder = {
          ...this.selectedOrder,
          ...orderObj,
          payload: { ...orderObj },
        };
        this.$root.$emit("bv::show::modal", "update-grade-modal");
      } else {
        this.confirmUpdateOrder(orderObj);
      }
    },
    handleOrderUpdate(orderObj) {
      if(orderObj && orderObj.uop_student_id && this.selectedOrder.uop_student_id !== orderObj.uop_student_id ){
        this.studentIdChanged = {
          changed: true,
          uop_student_id: orderObj.uop_student_id,
          full_name: this.selectedOrder.first_name + ' ' + this.selectedOrder.last_name
        }
      }
      this.localOrder = {
        ...this.selectedOrder,
        ...orderObj,
        payload: { ...orderObj },
      };
      this.$root.$emit("bv::show::modal", "update-modal");
    },
    checkIfItneedsModal(orderObj) {
      // check if grade_submission_type is selected
      // decide which modal to show based on selection
      if (orderObj && typeof orderObj.grade_type === "boolean") {
        return true;
      }
      return false;
    },
    isValidRecordForAction() {
      //Return false if banner_status false or grade_status true or grade_type == false
      if (
        !this.localOrder.banner_status ||
        this.localOrder.grade_status ||
        this.localOrder.grade_type == false
      )
        return false;
      return true;
    },
    log(lg) {
      console.log(lg);
    },
    setDefaultViews() {
      let currentPartners = this.$store.state.partners;
      let currentPartner = this.$store.state.user.partnerId;
      let isMatched = currentPartners.filter(
        (e) => e.partner_id === currentPartner
      );
      // console.log({ currentPartners, currentPartner, isMatched });
      //Set Default View
      if(this.$route.query.objectId) {
        this.selectedView = "";
        return;
      }
      if (this.$store.state.user.user_role == "admin") {
        if (isMatched.length && isMatched[0].partner_id !== -1) {
          if (isMatched[0].requires_validation) {
            this.selectedView = this.VIEWS.UNVALIDATED_REGISTRATIONS;
          }
        } else {
          this.selectedView = "View New Registrations";
        }
      }
      if (this.$store.state.user.user_role == "uop_admin") {
        if (isMatched.length && isMatched[0].partner_id !== -1) {
          if (isMatched[0].requires_validation) {
            this.selectedView = this.VIEWS.UNVALIDATED_REGISTRATIONS;
          }
        } else {
          this.selectedView =
            "View Registrations Needed To Be Entered in Banner";
        }
      }
      if (this.$store.state.user.user_role == "partner") {
        if (
            isMatched.length && 
            isMatched[0].partner_id !== -1 && 
            isMatched[0].requires_validation
           ) 
        {
         this.selectedView = this.VIEWS.UNVALIDATED_REGISTRATIONS;
          
        } else {
          this.selectedView = "View New Registrations";
        }
      }
      //console.log("selectedView", this.selectedView)
    },
    redirectLogin() {
      var pushObject = {path: "/"}
      if(this.$route.query) pushObject.query = this.$route.query
      this.$router.push(pushObject)
    }
  },
  async created() {

    this.VIEWS = ENUMS.VIEWS;
    //if (this.$store.state.user && this.$store.state.user.token) return;
    if (!window.localStorage.token) return this.redirectLogin();

    await this.$store.dispatch("refreshUser").then(() => {
      if(!this.$route.query.objectId) this.getViewsCountQuery();
      this.$store
        .dispatch("fetchPartners")
        .then(() => {
          // console.log("resPartners", res);
          this.setDefaultViews();
        })
        .catch(() => {
          // console.log("errPartners", err);
          // setting up default views
          this.setDefaultViews();
        });
    }).catch(() => {
      //If rejected redirect to login
      return this.redirectLogin()
    })
  },
  components: {
    Header,
    OrderTable,
    InspectSelectedCourse,
    ModalDeleteOrder,
    ModalProcessOrder,
    ModalBannerEnter,
    ModalBannerGrade,
    ModalRefund,
    ModalSendResubmit,
    Tabs,
    ModalNotes,
    ModalMessages,
    DatepickerFilter,
    ModalUpdateGradeOrder,
    ModalUpdateOrder,
    ModalValidateOrder,
    ModalFilter,
    ModalAssignments,
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  min-height: 40rem;
  height: 100%;
  background: #f5f5f5;
}


.main {
  margin: 2%;
  display: flex;
  flex-direction: column;
}

.nav-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
  gap: 10px;
  text-align: start;
}

.nav-left {
  display: flex;
  justify-content: center;
  align-items: center;
}
.labelContainer {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 30px;
  .title {
    font-size: 12px;
    font-weight: 600;
  }
  .subTitle {
    font-size: 12px;
  }
}
.nav-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.label-select {
  text-align: left;
  font-weight: 500;
  flex: 1;
}

.select-dropdown {
  flex: 2;
}

.view-main {
  flex: 1;
  background-color: white;
  padding: 0 2rem;
}

.view-header {
  display: flex;
}


.view-search {
  flex: 1;
  line-height: 16px;
  padding: 5px 8px;
}

.view-buttons {
  min-height: 1.5rem;
  display: flex;
  justify-content: flex-end;
}

.view-body {
  display: flex;
  flex-direction: column;
}
.view-main .table-controls {
  background-color: $gray-100;
}
.table-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 1rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-color: "#c7c7c7";
  font-size: 12px;
}
.clear-selection {
  cursor: pointer;
}

.btn-page {
  color: gray;
  font-weight: bold;
  cursor: pointer;
}

.btn-page:hover {
  color: blue;
}

.table-holder {
  flex: 1;
  max-width: 100%;
  // max-height: 22rem;
  overflow-x: scroll;
  // overflow-y: auto;
  margin-bottom: 2rem;
  padding-bottom: 0.6rem;
  th,
  td {
    font-size: 12px;
  }
}

tr:hover {
  background: rgb(235, 235, 235);
  cursor: default;
}

.tr-selected {
  color: red;
}

.inspect-holder {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.inspect-header {
  display: flex;
  background-color: lightgray;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}

.btn-tab {
  flex: 1;
  white-space: nowrap;
  cursor: pointer;
}
.btn-tab:hover {
  color: blue;
}
.ml20 {
  margin-left: 20px;
}
.mr20 {
  margin-right: 20px;
}
.p20 {
  padding: 20px;
}
.mtb20 {
  margin: 20px 20px;
}
.tableHeading {
  margin: 5px;
  text-align: left;
  font-weight: 600;
  font-size: 12px;
}
.main-action-wrapper {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  .search-div {
    flex-grow: 1;
    margin-right: 0.5rem;
    .v-select {
      background-color: white;
    }
  }
}
.filterInput {
  width: 40px;
  height: 20px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.controls-center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.controls-right {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-direction: row-reverse;
}
@media screen and (max-width: 670px) {
  .head-title > h4 {
    font-size: 18px;
  }
  .head-section {
    flex-direction: column;
    margin-top: 20px;
  }
  .nav-section {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
}

@media screen and (max-width: 830px) {
  .btn--actions {
    padding: 5px 30px;
  }
}
@media screen and (max-width: 340px) {
  .btn--actions {
    padding: 5px 10px;
    margin: 10px;
  }
}
.counter {
  width: 30px;
  height: 30px;
  font-size: 10px;
  border-radius: 50%;
  color: #fff;
  background-color: tomato;
}
</style>